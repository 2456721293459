import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, GridItem, GridRow } from './../Grid';
import { Text, Title } from './../Typography';
import { Link, QuickLink as QuickLinkData } from '../../generated/graphql';
import { QuickLinkItem, TextWrapper } from './styles';

type QuickLinkProps = Pick<QuickLinkData, 'headline' | 'quickLinkItems'>;

export function QuickLink(props: { data: QuickLinkProps }) {
  const { data } = props;
  return (
    <Grid>
      <GridRow columns={16}>
        <GridItem colStart={4} colSpan={10}>
          {data.headline && (
            <Title type="md" tag="h4" className="mb-8 md:mb-10">
              {data.headline}
            </Title>
          )}
          {data?.quickLinkItems &&
            data?.quickLinkItems.map((item) => (
              <QuickLinkItem
                key={item.id}
                link={item?.link as Link}
                className="w-full flex items-center justify-between p-5 text-left border-1 rounded-2xl mb-4"
              >
                <TextWrapper>
                  <Title type="xxs" tag="h5">
                    {item.buttonHeadline}
                  </Title>
                  {item.buttonSubheadline && (
                    <Text type="sm" tag="p" className="mt-2">
                      {item.buttonSubheadline}
                    </Text>
                  )}
                </TextWrapper>
                <FontAwesomeIcon icon={faChevronRight} size="1x" className="ml-4" />
              </QuickLinkItem>
            ))}
        </GridItem>
      </GridRow>
    </Grid>
  );
}

import styled from 'styled-components';
import { CorpLink } from '../CorpLink';

export const QuickLinkItem = styled(CorpLink)`
  border-color: rgba(1, 14, 84, 1);
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: rgba(230, 231, 238, 1);
  }
`;

export const TextWrapper = styled.div`
  max-width: 80%;
`;
